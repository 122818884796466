@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');
body, h1, h2, h3, h4, h5{
	font-family: sans-serif;
}
button{
	font-family: sans-serif;
}
.mainBodyContainer{
	/* padding-right: 0 !important;
    padding-left: 0 !important;	 */
	margin-bottom: 100px;
}
.mainBody{
    min-height: 500px;
}
.navbar{
	border-radius: 0 !important;
}
.menu-white{
	background-color: #ffffff !important;
	border-bottom: 1px solid #e8e7e7;
}
.menu-white .navbar-nav>.active>a, .menu-white .navbar-nav>.active>a:focus, .menu-white .navbar-nav>.active>a:hover{
	color: #2d2d2d !important;
	border-bottom: 2px solid #092c5d;
	background-color: #ffffff !important;
}
.menu-white .navbar-nav>li>a {
    color: #2d2d2d !important;
}
.menu-white .navbar-toggle:focus, .menu-white .navbar-toggle:hover{
	background-color: #ffffff !important;
}
.menu-white .navbar-toggle{
	border-color: #ffffff !important;
}
.menu-white .navbar-toggle .icon-bar{
	background-color: #092c5d;
}
.navbar-form{
	padding: 0!important;
    float: right;
    margin-right: 12px;
}
.rounded-circle {
    border-radius: 50%!important;
}
.profile-menu {
    top: 19px!important;
    right: 39px!important;
    left: auto!important;
    transform: translate3d(51px,27px,0px);
}
.divider {
    background: #474648;
    width: 100px;
    height: 2px;
    margin: 0 auto 30px;
}
.navbar-inverse .navbar-brand, .navbar-inverse .navbar-brand {
    color: #ffffff!important;
    font-size: 20px;
    font-weight: 700;
}
.navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
    border-color: transparent!important;
}
.navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border-color: transparent!important;
}
.navbar-default .navbar-collapse, .navbar-default .navbar-form
.navbar-header {
    float: none!important;
}
.btn.disabled, .btn:disabled, fieldset:disabled .btn{
	opacity: 0.50 !important;
}
.btn-blue{
	color:#fff !important;
	background-color:#007bff !important;
	border-color:#007bff !important;
	font-size: 16px !important;
}
.btn-blue:hover,.btn-blue.focus,.btn-blue:focus{
	color:#fff;
	background-color:#0069d9;
	border-color:#0069d9
}
.btn-blue:active{
	color:#fff;
	background-color:#0062cc;
	border-color:#0062cc
}
.btn-orange{
	color:#fff;
	background-color:#ff9900;
	border-color:#ff9900;
	font-size: 16px;
}
.btn-orange:hover,.btn-orange.focus,.btn-orange:focus{
	color:#fff;
	background-color:#ff9900 !important;
	border-color:#ff9900 !important;
	outline: 5px auto #ff9900 !important;
}
.btn-orange:active{
	color:#fff;
	background-color:#ff9900;
	border-color:#ff9900
}
.btn-green{
	color:#fff !important;
	background-color:#008548 !important;
	border-color:#008548 !important;
	font-size: 16px !important;
}
.btn-green:hover,.btn-green.focus,.btn-green:focus{
	color:#fff;
	background-color:#008548;
	border-color:#008548
}
.btn-green:active{
	color:#fff;
	background-color:#008548;
	border-color:#008548
}
.btn-red.disabled, .btn-red:disabled{
	background-color:#008548;
	border-color:#008548
}
.btn-red{
	color:#fff !important;
	background-color:#cf233d !important;
	border-color:#cf233d !important;
	font-size: 16px !important;
}
.btn-red:hover,.btn-red.focus,.btn-red:focus{
	color:#fff;
	background-color:#cf233d;
	border-color:#cf233d
}
.btn-red:active{
	color:#fff;
	background-color:#cf233d;
	border-color:#cf233d
}
.btn-red.disabled, .btn-red:disabled{
	background-color:#cf233d;
	border-color:#cf233d
}
.searchFrmHomeContainer{
	/*border-bottom: 1px solid #e4e4e4;*/
	padding-bottom: 25px;
}
.searchFrmHome label{
	display: block;
	font-size:22px;
}
.searchFrmWrap{
	/*margin-top: 50px;*/
}
.subHead{
	color: #767676 !important;
	font-size: 10px;
	font-weight: normal;
}
.frmMainHead{
	display: block !important;
	margin-bottom: -14px;
}
.latestJobs{

}
.jobHeading{
	text-align: center;
    font-size: 25px;
    font-weight: 700;
    margin: 30px;
}
.searchFrmHome .form-group{
    padding-right: 2px;
    padding-left: 2px;
}
.searchFrmHome .form-control{
	width:100% !important;
	padding: 23px;
}
.bannerhead{
	padding-top: 18px;
    font-size: 14px;
}
.bannerhead a{
	font-size: 17px;
	text-decoration: none;
	color: #085ff7 !important;
}
.latestSingleJob{
	border-color: #d4d2d0;
    border-width: 1px;
    border: 1px solid #ececec;
    padding: 1.25rem !important;
    border-radius: 8px;
    margin: 0 0 8px;
}
.jobTitle{
	font-weight: bold;
	font-size: 18px;
	line-height: 40px;
}
.company{
	color: #767676 !important;
	font-weight: normal;
	display: block;
	margin-left: 15px;
}
.location{
	color: #767676 !important;
	font-weight: normal;
	display: block;
	margin-left: 15px;
}
.summary{
	font-size:15px;
	line-height: 22px;
	padding-top: 13px !important;
	padding-bottom: 13px !important;
}
.footer{
    margin-top: 50px !important;
    text-align: center;
    border-top: 0.80px solid #dcdcdc;
    padding-top: 20px;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .frmHomeBtnLabel{
  	display: none !important;
  }
}

.errorList li{
	color: #ff5252;
}

label.error{
	background: url("") no-repeat;
	color: #ff5252;
    font-weight: normal;
    margin-left: .3em;
    font-size: 14px;
}
    .form-control{
		height: 40px;
		box-shadow: none;
	}
	.form-control:focus{
		border-color: #007bff;
	}
    .form-control, .btn{        
        border-radius: 3px;
    }
	.entry-form form{
		color: #999;
		border-radius: 3px;
    	margin-bottom: 15px;
        background: transparent !important;
        box-shadow: none !important;
        padding: 30px;
	}
	.signup-form{
		max-width: 400px !important;
		/* margin: 0 auto; */
	}
	.signup-form h2{
		color: #535252;
        margin: 0 0 15px;
		position: relative;
		text-align: center;
    }
	.signup-form h2:before, .signup-form h2:after{
		content: "";
		height: 2px;
		width: 16%;
		background: #d4d4d4;
		position: absolute;
		top: 50%;
		z-index: 2;
	}	
	.signup-form h2:before{
		left: 0;
	}
	.signup-form h2:after{
		right: 0;
	}
    .signup-form .hint-text{
		color: #999;
		margin-bottom: 30px;
		text-align: center;
	}
    .signup-form form{
		color: #999;
		border-radius: 3px;
    	margin-bottom: 15px;
        /* background: #f2f3f7;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3); */
        padding: 30px;
    }
	.signup-form .form-group{
		margin-bottom: 20px;
	}
	.signup-form input[type="checkbox"]{
		margin-top: 3px;
	}
	.signup-form .btn{        
        font-size: 16px;
        /* font-weight: bold;		 */
		min-width: 140px;
        outline: none !important;
    }
	.signup-form .row div:first-child{
		padding-right: 10px;
	}
	.signup-form .row div:last-child{
		padding-left: 10px;
	}    	
    .signup-form a{
		color: #007bff;
		text-decoration: underline;
	}
    .signup-form a:hover{
		text-decoration: none;
	}
	.signup-form form a{
		color: #007bff;
		text-decoration: none;
	}	
	.signup-form form a:hover{
		text-decoration: underline;
	}  
.tableheading{
	color: darkgrey;
    margin-bottom: 7px;
    font-size: 14px;
}
.frmlabel{
    color: #7d7c7c;
    font-weight: 700;
}
.footerAbout{
    margin: 15px 0 20px 0;
    font-size: 12px;
    line-height: 160%;
    color: #666;
}
.footerMenu ul{
	display: inline-block;
}
.footerMenu ul li{
	display: inline-block;
	margin-right: 10px;
}
.footerMenu ul li a{
	color: #000;
}
.footerCopyRight{
	text-align: center;
	padding-top: 0 !important;
}
.footerCopyRight a{
	color: #000;
}
.postelement{
	margin-bottom: 15px;
}
.imgCaption {
    text-align: left;
    position: absolute;
    background-color: rgba(0, 0, 0, .5)!important;
    padding: 8px;
    bottom: 0;
    left: 0;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
    color: #fff;
}
.item{
	background-color: #f1f1f1;
	text-align: center;
    margin-bottom: 30px;
    position: relative;
}
.itemImage {
    width: 100%;
}
.itemBtn{
	padding-top: 12px;
    padding-bottom: 35px;
}
.shareBtn a{
	font-size: 16px!important;
	padding: .78571429em;
	text-decoration: none;
	-webkit-box-shadow: 0 0 0 1px rgba(34, 36, 38, .15) inset;
    box-shadow: inset 0 0 0 1px rgba(34, 36, 38, .15);
}
.downloadBtn{
	margin-top: -8px;
	padding: 6px 12px !important;
}
.textarea_no_resize {
    resize: none;
}
.media-empty-view {
    max-height: 280px;
    border: 2px dashed #c3c3c3d1;
    border-radius: 2px;
    color: #767676;
}
.preview_image {
    max-height: 266px;
}
.preview_image {
    display: block;
    margin: 0 auto;
    max-width: 100%;
}
.photo_submit_image {
    cursor: pointer;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 80%;
    margin-left: 18px;
    left: 0;
    top: 0;
    font-size: 0;
}
.homeItem{
	width: 100%;
    height: 280px;
}
.homeItemImage{
	width: 100%;
	height: 85%;
	object-fit: cover;
}
.homeItemDescription{
	padding-top: 10px;
    display: block;
    font-size: 13px;
    font-weight: bold;
    line-height: 1;
    text-align: center;
}
.homeItemLink, .homeItemLink:hover{
	text-decoration: none;
	color: #000;
}
.relatedItem{
	width: 100%;
    height: 280px;
}
.relatedItemImage{
    width: 100%;
    height: 85%;
    object-fit: cover;
    border-radius: 10px;
}
.relatedHeading{
	margin-bottom: 25px;
    text-align: center;
    font-weight: bold;
}
.itemAboutHead{
	font-size: 28px;
    font-weight: bold;
    text-align: center;
}
.itemAboutPara{
	padding-top: 5px;
    font-size: 18px!important;
    line-height: 1.4285em;
    text-align: center;
    margin:0 auto;
    margin-bottom: 30px;
    /*max-width: 800px;*/
    word-spacing: 1px;
    text-align: justify;
}
.editBtn{
	margin-bottom: 15px;
}
.article{

}
.article h1{
	font-size: 25px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 25px;
}
.article p{
	font-size: 18px;
	margin-bottom: 25px;
}
.articleImg{
	display: block;
    margin: 0 auto;
    border-radius: 6px;
    max-width: 100%;
}
.googleAds{
	margin: 0 auto 21px;
	clear: both;
	text-align: center;
}
.forgetPassword{
	text-decoration: none !important;
}
.btnCenter{
	margin: 0 auto;
    display: block !important;
}
.largeBtn{
	width: 90%;
}
.largeBtn a{
	text-decoration: none;
  color: #fff;
}
.marginBottom{
	margin-bottom: 20px;
}
.paddingBottom{
	padding-bottom: 20px;
}
.bottomGrey{
	border-bottom: 1px solid lightgrey;
}
.siteTitle{
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding-top: 12px;
    padding-bottom: 14px;
	background: #fff;
	color: #0752ad;
	display: flex;
	align-items: center;
	cursor: pointer;
}
.siteTitle.green{
	color: #008548;
}
.siteTitle.red{
	color: #cf233d;
}
.roundLogo{
    color: #fff;
    width: 32px;
    height: 32px;
    display: flex;
    font-size: 1rem;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    background-color: #0752ad;
}
.roundLogo a{
  text-decoration: none;
  color: #fff;
}
.roundLogo.green{
	background-color: #008548;
}
.roundLogo.red{
	background-color: #cf233d;
}
.titleTxt{
	margin: 0 16px;
}
.titleTxt a{
  text-decoration: none;
  color: #0752ad;
}
.startBody{
	margin-bottom: 35px;
}
.MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}
.backIcon{
	margin-top: 13px;
    margin-left: 20px;
}
.backIcon.red{
    color: #cf233d;
}
.backIcon.green{
    color: #008548;
}
.entryHead{
	color: #adadad;
	padding: 16px 0 16px 12px;
	background: #f1f1f1;
	line-height: 16px;
}
.whiteHead{
	background: #fff;
}
.entryRow{
	color: #9b9b9b;
    font-size: 0.85rem;
    line-height: 16px;
    border-bottom: 1px solid #e7e7e7;
    background-color: #fff;
}
.textRed{
	color: #cf233d;
}
.textGreen{
	color: #008548;
}
.moneyTxt{
	font-size: 18px;
    min-height: 48px;
    padding-top: 16px;
    padding-right: 8px;
	word-wrap: break-word;
}
.moneyTxtBold{
	font-size: 18px;
    min-height: 48px;
    font-weight: 500;
    padding-top: 16px;
    padding-right: 8px;
	word-wrap: break-word;
}
.dateTxt{
	font-size: 14px;
    min-height: 48px;
    font-weight: 400;
    padding-top: 16px;
    padding-right: 8px;
}
.pullRight{
	text-align: right;
}
.overFlowY{
    margin-bottom: 110px;
	overflow-y: auto;
}
.fixedNav{
	background: #fff;
}
.logoutBtn{
    padding-top: 18px;
    cursor: pointer;
    font-size: 14px;
	color: #e86666;
}
.transDetail{
	font-size: 10px;
    margin-top: 6px;
    color: #b3b3b3;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.detailofTrans{
	margin-top: 70px;
	color: #797878;
}
.detailRow{
	font-size: 18px;
	margin-bottom: 20px;
}
.dateFilter .react-datepicker__input-container input{
	font-size: 13px;
    padding: 0 0 0 10px;
    color: #939393;
}
.footerBottom{
	background: #fff;
}
.footerBottom a{
	text-decoration: none;
}

.sidenav {
	height: 100%;
	width: 0;
	position: fixed;
	z-index: 1;
	top: 0;
	left: -25px;
	background-color: #fff;
	overflow-x: hidden;
	transition: 0.5s;
	padding-top: 60px;
  }
  
  .sidenav a {
	padding: 8px 8px 8px 32px;
	text-decoration: none;
	font-size: 18px;
	color: #818181;
	display: block;
	transition: 0.3s;
  }
  
  .sidenav a:hover {
	background-color: #f1f1f1;
  }
  
  .sidenav .closebtn {
	position: absolute;
	top: 0;
	right: 25px;
	font-size: 36px;
	margin-left: 50px;
  }

  .sidenav .closebtn:hover{
	background-color: transparent;
	cursor: pointer;
  }
  
  @media screen and (max-height: 450px) {
	.sidenav {padding-top: 15px;}
	.sidenav a {font-size: 18px;}
  }
.menuIcon{
    font-size: 23px;
    cursor: pointer;
    padding-top: 12px;
    display: flex;
    padding-left: 17px;
}
textarea.form-control{
	height: 100%;
}
.hideBlogDescription{
	display: none;
}
.showBlogDescription{
	display: block;
}
.blogTitle{
    color: #084a83;
    font-size: 17px;
    cursor: pointer;
}
/* .menuIcon:before {
    content: "\f0c9";
} */